var Koki = (function () {
  var e = $(window).width(),
    t = function () {
      //   jQuery(".default-select").length > 0 &&
      //     jQuery(".default-select")?.selectpicker();
    },
    n = function () {
      $("#preloader").fadeOut(500), $("#main-wrapper").addClass("show");
    },
    a = function () {
      jQuery("#menu").length > 0 && $("#menu").metisMenu(),
        jQuery(".metismenu > .mm-active ").each(function () {
          !jQuery(this).children("ul").length > 0 &&
            jQuery(this).addClass("active-no-child");
        });
    },
    o = function () {
      jQuery("#ckeditor").length > 0 &&
        ClassicEditor.create(document.querySelector("#ckeditor"), {})
          .then((e) => {
            window.editor = e;
          })
          .catch((e) => {
            console.error(e.stack);
          });
    },
    c = function () {
      $(document).on("change", "#checkAll", function () {
        $(
          "td input:checkbox, .email-list .custom-checkbox input:checkbox"
        ).prop("checked", $(this).prop("checked"));
      });
    },
    s = function () {
      $(document).on("click", ".nav-control", function () {
        $("#main-wrapper").toggleClass("menu-toggle"),
          $(".hamburger").toggleClass("is-active");
      });
    },
    i = function () {
      for (
        var e = window.location,
          t = $("ul#menu a")
            .filter(function () {
              return this.href == e;
            })
            .addClass("mm-active")
            .parent()
            .addClass("mm-active");
        t.is("li");

      )
        t = t.parent().addClass("mm-show").parent().addClass("mm-active");
    },
    l = function () {
      $(document).on("click", "ul#menu>li" , function () {
        let e = $("body").attr("data-sidebar-style");
        "mini" === e &&
          (console.log($(this).find("ul")), $(this).find("ul").stop());
      });
    },
    r = function () {
      var e = window.outerHeight,
        e = window.outerHeight;
      (e > 0 ? e : screen.height) &&
        $(".content-body").css("min-height", e + 60 + "px");
    },
    d = function () {
      $('a[data-action="collapse"]').on("click", function (e) {
        e.preventDefault(),
          $(this)
            .closest(".card")
            .find('[data-action="collapse"] i')
            .toggleClass("mdi-arrow-down mdi-arrow-up"),
          $(this).closest(".card").children(".card-body").collapse("toggle");
      }),
        $('a[data-action="expand"]').on("click", function (e) {
          e.preventDefault(),
            $(this)
              .closest(".card")
              .find('[data-action="expand"] i')
              .toggleClass("icon-size-actual icon-size-fullscreen"),
            $(this).closest(".card").toggleClass("card-fullscreen");
        }),
        $('[data-action="close"]').on("click", function () {
          $(this).closest(".card").removeClass().slideUp("fast");
        }),
        $('[data-action="reload"]').on("click", function () {
          var e = $(this);
          e.parents(".card").addClass("card-load"),
            e
              .parents(".card")
              .append(
                '<div class="card-loader"><i class=" ti-reload rotate-refresh"></div>'
              ),
            setTimeout(function () {
              e.parents(".card").children(".card-loader").remove(),
                e.parents(".card").removeClass("card-load");
            }, 2e3);
        });
    },
    u = function () {
      let e = $(".header").innerHeight();
      $(window).scroll(function () {
        "horizontal" === $("body").attr("data-layout") &&
          "static" === $("body").attr("data-header-position") &&
          "fixed" === $("body").attr("data-sidebar-position") &&
          ($(this.window).scrollTop() >= e
            ? $(".deznav").addClass("fixed")
            : $(".deznav").removeClass("fixed"));
      });
    },
    m = function () {
      e <= 991 &&
        (jQuery(".menu-tabs .nav-link").on("click", function () {
          jQuery(this).hasClass("open")
            ? (jQuery(this).removeClass("open"),
              jQuery(".fixed-content-box").removeClass("active"),
              jQuery(".hamburger").show())
            : (jQuery(".menu-tabs .nav-link").removeClass("open"),
              jQuery(this).addClass("open"),
              jQuery(".fixed-content-box").addClass("active"),
              jQuery(".hamburger").hide());
        }),
        jQuery(".close-fixed-content").on("click", function () {
          jQuery(".fixed-content-box").removeClass("active"),
            jQuery(".hamburger").removeClass("is-active"),
            jQuery("#main-wrapper").removeClass("menu-toggle"),
            jQuery(".hamburger").show();
        }));
    },
    h = function () {
      jQuery(".bell-link").on("click", function () {
        jQuery(".chatbox").addClass("active");
      }),
        jQuery(".chatbox-close").on("click", function () {
          jQuery(".chatbox").removeClass("active");
        });
    },
    f = function () {
      $(".btn-number").on("click", function (e) {
        e.preventDefault(),
          (fieldName = $(this).attr("data-field")),
          (type = $(this).attr("data-type"));
        var t = $("input[name='" + fieldName + "']"),
          n = parseInt(t.val());
        isNaN(n)
          ? t.val(0)
          : "minus" == type
          ? t.val(n - 1)
          : "plus" == type && t.val(n + 1);
      });
    },
    p = function () {
      jQuery(".dz-chat-user-box .dz-chat-user").on("click", function () {
        jQuery(".dz-chat-user-box").addClass("d-none"),
          jQuery(".dz-chat-history-box").removeClass("d-none");
      }),
        jQuery(".dz-chat-history-back").on("click", function () {
          jQuery(".dz-chat-user-box").removeClass("d-none"),
            jQuery(".dz-chat-history-box").addClass("d-none");
        }),
        jQuery(".dz-fullscreen").on("click", function () {
          jQuery(".dz-fullscreen").toggleClass("active");
        });
    },
    v = function () {
      jQuery(".dz-fullscreen").on("click", function (e) {
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement ||
        document.msFullscreenElement
          ? document.exitFullscreen
            ? document.exitFullscreen()
            : document.msExitFullscreen
            ? document.msExitFullscreen()
            : document.mozCancelFullScreen
            ? document.mozCancelFullScreen()
            : document.webkitExitFullscreen && document.webkitExitFullscreen()
          : document.documentElement.requestFullscreen
          ? document.documentElement.requestFullscreen()
          : document.documentElement.webkitRequestFullscreen
          ? document.documentElement.webkitRequestFullscreen()
          : document.documentElement.mozRequestFullScreen
          ? document.documentElement.mozRequestFullScreen()
          : document.documentElement.msRequestFullscreen &&
            document.documentElement.msRequestFullscreen();
      });
    },
    g = function () {
      $(".heart").on("click", function () {
        $(this).toggleClass("heart-blast");
      });
    },
    b = function () {
      jQuery(".show-pass").on("click", function () {
        jQuery(this).toggleClass("active"),
          "password" == jQuery("#dz-password").attr("type")
            ? jQuery("#dz-password").attr("type", "text")
            : "text" == jQuery("#dz-password").attr("type") &&
              jQuery("#dz-password").attr("type", "password");
      });
    },
    k = function () {
      $(".custom-file-input").on("change", function () {
        var e = $(this).val().split("\\").pop();
        $(this).siblings(".custom-file-label").addClass("selected").html(e);
      });
    },
    C = function () {
      $(".dz-load-more").on("click", function (e) {
        e.preventDefault(), $(this).append(' <i class="fa fa-refresh"></i>');
        var t = $(this).attr("rel"),
          n = $(this).attr("id");
        $.ajax({
          method: "POST",
          url: t,
          dataType: "html",
          success: function (e) {
            $("#" + n + "Content").append(e), $(".dz-load-more i").remove();
          },
        });
      });
    };
  return {
    init: function () {
      n(),
        a(),
        c(),
        s(),
        l(),
        r(),
        d(),
        u(),
        m(),
        h(),
        f(),
        p(),
        v(),
        o(),
        g(),
        b(),
        t(),
        k(),
        C(),
        i();
    },
    load: function () {
      n(), t();
    },
    resize: function () {},
  };
})();
jQuery(document).ready(function () {
  $('[data-toggle="popover"]').popover();
  ("use strict");
  Koki.init();
}),
  jQuery(window).on("load", function () {
    "use strict";
    Koki.load();
  }),
  jQuery(window).on("resize", function () {
    "use strict";
    Koki.resize();
  });
